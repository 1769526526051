import {
  CustomerInformation,
  LoginParams,
  RequestOTPParamsType,
  LoginDataResponse,
  UpdateCustomerInformation
} from './types';

import axiosInstance from 'services/common/instance';

export const getCustomerInformationService = async (): Promise<CustomerInformation> => {
  const res = await axiosInstance.get('customers/profile');
  return res.data.data;
};

export const loginWithTokenService = async (token: string): Promise<LoginDataResponse> => {
  const res = await axiosInstance.post('customers/login-with-token', { token });
  return res.data.data;
};

export const updateCustomerInformationService = async (
  params: UpdateCustomerInformation,
): Promise<void> => {
  await axiosInstance.post('customers/profile', params);
};

export const loginCustomerService = async (params: LoginParams): Promise<LoginDataResponse> => {
  const res = await axiosInstance.post('customers/login', params);
  return res.data.data;
};

export const requestOTPService = async (
  params: RequestOTPParamsType,
): Promise<CustomerInformation> => {
  const res = await axiosInstance.post('customers/send-otp', params);
  return res.data.data;
};
