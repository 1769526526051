/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { datePickerLocalized } from './helpers';

import { formatDateDDMMYYYY } from 'utils/functions';

interface DatePickerProps {
  maxDate?: Date;
  minDate?: Date;
  defaultDate?: Date;
  handleSelectDate?: (date: Date) => void;
  placeholder?: string,
}

type Props = {
  value?: string;
  onClick?: () => void;
};
const InputRef: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
  {
    value,
    onClick
  },
  ref,
) => (
  <div
    onClick={onClick}
    ref={ref}
    className="m-datePicker_customInput"
  >
    {value || 'Nhập ngày, tháng, năm sinh'}
  </div>
);

const CustomInput = forwardRef(InputRef);
const DatePickerCustom: React.FC<DatePickerProps> = (
  {
    defaultDate,
    handleSelectDate,
    maxDate,
    minDate,
    placeholder,
  }
) => {
  const [value, setValue] = React.useState<Date>();
  return (
    <DatePicker
      maxDate={maxDate}
      // locale={locale}
      locale={datePickerLocalized('vi')}
      minDate={minDate}
      wrapperClassName="m-datePicker"
      dateFormat="dd/MM/yyyy"
      selected={value || defaultDate}
      placeholderText={placeholder}
      showMonthDropdown
      showYearDropdown
      scrollableYearDropdown
      dropdownMode="select"
      onChange={(date: Date) => {
        setValue(date);
        if (handleSelectDate) handleSelectDate(date);
      }}
      customInput={(
        <CustomInput
          value={formatDateDDMMYYYY(value)}
        />
      )}
    />
  );
};

export default DatePickerCustom;
