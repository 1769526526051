/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import useDidMount from 'hooks/useDidMount';
import {
  getAccessToken,
  setAccessToken,
} from 'services/common/storage';
import { loginWithTokenService } from 'services/customer';
import { getProfileAction, setUserProfile } from 'store/auth';
import { useAppDispatch } from 'store/hooks';

const useInitialRender = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const loginToken = searchParams.get('token');
  const navigator = useNavigate();
  const [isDone, setIsDone] = useState(false);

  const expiredAction = () => {
    // if (location.pathname !== '/login') {
    //   navigator('/login', {
    //     state: {
    //       from: `${location.pathname}${location.search}`
    //     }
    //   });
    // } else {
    //   navigator('/login');
    // }
    setIsDone(true);
  };

  useDidMount(async () => {
    try {
      if (loginToken) {
        await loginWithTokenService(loginToken).then((data) => {
          setAccessToken(data.token);
          dispatch(setUserProfile(data.user));
          navigator(location.search);
          setIsDone(true);
        }).catch(() => {
          setAccessToken('');
          expiredAction();
        });
      } else {
        const token = getAccessToken();
        if (token) {
          await dispatch(getProfileAction()).unwrap().then(() => {
            setIsDone(true);
          }).catch(() => {
            expiredAction();
          });
          setIsDone(true);
        } else {
          expiredAction();
        }
      }
    } catch {
      expiredAction();
    }
  });
  return isDone;
};

export default useInitialRender;
