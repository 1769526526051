import React from 'react';
import { Controller } from 'react-hook-form';
import { Typography } from 'tfc-components';

import ButtonCustom from 'components/atoms/ButtonCustom';
import Input from 'components/atoms/Input';

interface Step1Props {
  handleStep: React.Dispatch<React.SetStateAction<number>>;
}

export interface Step1Form {
  phone: string
}

const Step1: React.FC<Step1Props> = ({
  handleStep
}) => (
  <div className="p-login_step1">
    <Typography.Heading
      extendClasses="p-login_heading fs-24x30 color-white"
      fontweight="700"
      textStyle="center"
    >
      ĐĂNG NHẬP
    </Typography.Heading>
    <Typography.Text
      extendClasses="p-login_subHeading fs-16x20 color-white"
      textStyle="center"
    >
      Vui lòng đăng nhập để hưởng những đặc quyền
      <br />
      dành cho thành viên
    </Typography.Text>
    <div className="p-login_form">
      <Controller
        name="phone"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <Input
              required
              value={value}
              placeholder="Nhập số điện thoại"
              onChange={onChange}
            />
            {
              error && (
                <div className="p-login_form_errorText">
                  <Typography.Text
                    extendClasses="color-white"
                  >
                    {error.message}
                  </Typography.Text>
                </div>
              )
            }
          </>
        )}
      />
      <ButtonCustom onClick={() => handleStep(2)}>
        TIẾP TỤC
      </ButtonCustom>
    </div>
  </div>
);

export default React.memo(Step1);
