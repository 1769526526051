import React from 'react';

import Container from '../Container';

import useWindowDimensions from 'hooks/useWindowDemensions';

interface MainLayoutProps {
  children?: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { height } = useWindowDimensions();
  return (
    <main className="o-mainLayout" style={{ height: `${height}px`, maxHeight: `${height}px` }}>
      <Container>
        <div className="o-mainLayout_content">{children}</div>
      </Container>
    </main>
  );
};

export default MainLayout;
