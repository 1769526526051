import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { Input, Typography } from 'tfc-components';

import ButtonCustom from 'components/atoms/ButtonCustom';
import mapModifiers from 'utils/functions';

type Props = {
  error?: string;
  loading?: boolean;
  handleResendOTP?: () => void;
  handleVerify?: (otp: string) => void;
  handleChange?: React.Dispatch<React.SetStateAction<string[]>>
};
const OTP: React.FC<Props> = ({
  error, loading, handleResendOTP, handleVerify, handleChange
}) => {
  const numInput = 6;
  const [otp, setOtp] = useState<string[]>(Array(numInput).fill(''));

  const otpRef = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  useEffect(() => {
    if (handleChange) {
      handleChange(otp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  return (
    <div className="o-otp">
      <div className="o-otp_wrapper">
        <div className="o-otp_input">
          {otp.map((_, idx) => (
            <Fragment key={`item${idx.toString()}`}>
              <Input
                id={`OTP${idx + 1}`}
                name={`OTP${idx + 1}`}
                type="number"
                maxLength={1}
                ref={otpRef[idx]}
                extendClasses={mapModifiers('custom-input', error ? 'error' : '')}
                value={otp[idx]}
                onFocus={({ target }) => target.select()}
                onChange={({ target: { value } }) => {
                  const intValue = parseInt(value, 10);
                  const formatedValue = intValue ? String(intValue % 10) : value;

                  setOtp((oldOtp) => oldOtp.map((v, nth) => (idx === nth ? formatedValue : v)));

                  if (value.length > 0) {
                    if (idx < numInput - 1) {
                      otpRef[idx + 1].current?.focus();
                    }
                  }
                }}
                onKeyUp={({ key }) => {
                  const { current } = otpRef[idx];
                  if (!current) {
                    return;
                  }
                  if (key === 'Backspace' && idx > 0) {
                    setOtp((oldOtp) => oldOtp.map((v, nth) => (idx === nth ? '' : v)));
                    current.value = '';
                    otpRef[idx - 1].current?.focus();
                  }
                }}
                onPaste={(e) => {
                  e.preventDefault();
                  const pastedData = e.clipboardData.getData('text');
                  const pastedValues = pastedData.split('').slice(0, numInput);
                  setOtp(pastedValues);
                }}
              />
            </Fragment>
          ))}
        </div>
      </div>
      <div className="o-otp_button">
        <ButtonCustom
          type="button"
          onClick={() => handleVerify && handleVerify(otp.join(''))}
          disabled={loading || otp.join('').length !== numInput}
          loading={loading}
        >
          XÁC NHẬN
        </ButtonCustom>
      </div>
      <div className="o-otp_resendTextWrap">
        <span
          onClick={handleResendOTP && handleResendOTP}
          className="fs-16x20 color-white o-otp_resendText"
        >
          Gửi lại
        </span>
        <Typography.Text
          type="span"
          extendClasses="fs-16x20 color-white o-otp_noReceive"
          fontweight="600"
        >
          {' '}
          mã xác thực cho tôi
        </Typography.Text>
      </div>
    </div>
  );
};

export default OTP;
