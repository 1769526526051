import dayjs from 'dayjs';
import React, {
  useState, useMemo, useRef, useEffect,
  useCallback
} from 'react';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import QRCode from 'react-qr-code';
import { useInfiniteQuery, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  Loading, Tabs, Typography, Image
} from 'tfc-components';

import ModalPolicy from './modalPolicy';

import headerMb from 'assets/images/header-mb.png';
import headerPc from 'assets/images/header-pc.png';
import ButtonCustom from 'components/atoms/ButtonCustom';
import DatePickerCustom from 'components/molecules/DatePicker';
import Container from 'components/organisms/Container';
import CustomModal from 'components/organisms/Modal';
import useDeviceQueries from 'hooks/useDeviceQueries';
import useScrollInfinite from 'hooks/useScrollInfinite';
import {
  updateCustomerInformationService,
} from 'services/customer';
import {
  getProjectGiftsService,
  getRewardHistoryService,
  createPointExchangeService,
} from 'services/rewards';
import {
  PointExchangeDataList,
  GiftData,
} from 'services/rewards/types';
import { getProfileAction } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { PROJECT_CODE } from 'utils/constant';
import mapModifiers from 'utils/functions';

const defaultPageSize = 15;

const TabList = [
  {
    value: 'giftsList',
    label: 'Danh sách quà'
  },
  {
    value: 'history',
    label: 'Lịch sử đổi quà'
  }
];

type CreatePointExchangeParams = {
  code: string,
  type: string,
};

type UpdateProfileForm = {
  birthday: string,
};

const Home: React.FC = () => {
  const { isMobile } = useDeviceQueries();
  const navigator = useNavigate();
  const { profileData } = useAppSelector((state) => state.auth);
  const [tabValue, setTabValue] = useState('giftsList');
  const [exchangeItem, setExchangeItem] = useState<PointExchangeDataList | undefined>(undefined);
  const [height, setHeight] = useState(0);
  const [isHideUpdate, setIsHideUpdate] = useState(false);
  const [modalPolicy, setModalPolicy] = useState(false);
  const [modal, setModal] = useState<{
    type: string,
    params: string,
    isOpen: boolean
    message: string,
    giftType?: string,
  }>({
    type: '',
    params: '',
    isOpen: false,
    message: '',
    giftType: undefined
  });
  const [modalLogin, setModalLogin] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [modalSuccess, setModalSuccess] = useState({
    isOpen: false,
    image: '',
    message: ''
  });
  const dispatch = useAppDispatch();
  const headerRef = useRef<HTMLDivElement>(null);
  const infoRef = useRef<HTMLDivElement>(null);

  const method = useForm<UpdateProfileForm>({
    mode: 'onSubmit',
    defaultValues: {
      birthday: '',
    },
  });

  // gifts list
  const {
    data: giftsRes,
    isLoading: listLoading,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(
    ['getProjectGiftsService', tabValue],
    ({ pageParam = 1 }) => getProjectGiftsService({
      projectCode: PROJECT_CODE,
      page: pageParam,
      limit: defaultPageSize,
    }),
    {
      getNextPageParam: (lastPage) => (
        lastPage.meta.page < lastPage.meta.totalPages
          ? lastPage.meta.page + 1 : undefined),
      enabled: tabValue === 'giftsList',
    },
  );

  const dataGifts = useMemo(() => {
    if (giftsRes) {
      let list: GiftData[] = [];
      giftsRes.pages.forEach((ele) => {
        const temp = ele.data?.map((item) => ({
          ...item
        })) || [];
        list = [...list, ...temp];
      });
      const month = profileData?.birthday ? parseInt(profileData?.birthday.split('/')[1], 10) : 0;
      const currentMonth = dayjs().month() + 1;
      if (!profileData || (currentMonth !== month)) {
        return list.filter((item) => item.type !== 'birthday');
      }
      return list;
    }
    return [];
  }, [giftsRes, profileData]);

  const handleLoadMoreGifts = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  // history list
  const {
    data: historyRes,
    isLoading: historyLoading,
    hasNextPage: hasNextPageHistory,
    fetchNextPage: fetchNextPageHistory
  } = useInfiniteQuery(
    ['getRewardHistoryService', tabValue],
    ({ pageParam = 1 }) => getRewardHistoryService({
      page: pageParam,
      limit: defaultPageSize,
    }),
    {
      getNextPageParam: (lastPage) => (
        lastPage.meta.page < lastPage.meta.totalPages
          ? lastPage.meta.page + 1 : undefined),
      enabled: tabValue === 'history',
    },
  );

  const historyData = useMemo(() => {
    if (historyRes) {
      let list: PointExchangeDataList[] = [];
      historyRes.pages.forEach((ele) => {
        const temp = ele.data?.map((item) => ({
          ...item
        })) || [];
        list = [...list, ...temp];
      });
      return list;
    }
    return [];
  }, [historyRes]);

  const handleLoadMoreHistory = () => {
    if (hasNextPageHistory) {
      fetchNextPageHistory();
    }
  };

  const { mutate, isLoading: loadingCreate } = useMutation(
    ['createPointExchangeService'],
    async (params: CreatePointExchangeParams) => createPointExchangeService({
      project_code: PROJECT_CODE,
      gifts: [
        {
          gift_code: params.code,
          quantity: 1,
          type: params.type
        }
      ]
    }),
    {
      onSuccess: async (_, params) => {
        const giftItem = dataGifts.find((item) => item.code === params.code);
        setModal({ ...modal, isOpen: false });
        await dispatch(getProfileAction()).unwrap().then(async () => {
          setModalSuccess({
            image: giftItem?.image || '',
            isOpen: true,
            message: giftItem?.name || ''
          });
        });
      },
      onError: () => {
        setModal({
          type: 'info',
          params: '',
          isOpen: true,
          message: 'Đổi quà thất bại!'
        });
      }
    }
  );

  const { mutate: updateProfileMutate } = useMutation(
    ['updateCustomerInformationService', profileData],
    async (birthday: string) => updateCustomerInformationService({
      name: profileData?.name || '',
      birthday
    }),
    {
      onSuccess: () => {
        setModal({
          type: 'info',
          params: '',
          isOpen: true,
          message: 'Cập nhật thông tin thành công!'
        });
      },
      onError: () => {
        setModal({
          type: 'info',
          params: '',
          isOpen: true,
          message: 'Cập nhật thông tin thất bại!'
        });
      },
      onSettled: () => {
        setModalUpdate(false);
      }
    }
  );

  const checkDisabled = useCallback((code: string) => {
    if (profileData) {
      return profileData?.birthdayGiftReceived?.some((el) => el.giftCode === code);
    }
    return false;
  }, [profileData]);

  const handleClickModal = (params: string, type: string, giftType: string) => {
    if (type === 'create') {
      mutate({
        code: params,
        type: giftType
      });
    }
  };

  const handleUpdate = (data: UpdateProfileForm) => {
    if (data.birthday) {
      updateProfileMutate(dayjs(data.birthday).format('DD/MM/YYYY'));
    } else {
      setModalUpdate(false);
    }
  };

  const { setNode } = useScrollInfinite(tabValue === 'giftsList' ? handleLoadMoreGifts : handleLoadMoreHistory);
  useEffect(() => {
    const updateHeight = () => {
      if (infoRef.current && headerRef.current) {
        const elementHeight = infoRef.current.clientHeight + headerRef.current.clientHeight;
        setHeight(elementHeight);
      }
    };

    updateHeight();

    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [tabValue, profileData, dataGifts]);

  useEffect(() => {
    if ((profileData && !profileData.birthday) && !modalPolicy && !isHideUpdate) {
      setModalUpdate(true);
      setIsHideUpdate(true);
    }
  }, [profileData, isHideUpdate, setIsHideUpdate, modalPolicy]);

  useEffect(() => {
    setModalPolicy(true);
  }, []);
  // if (!profileData) return null;
  return (
    <div className="p-home">
      <div className="p-home_header" ref={headerRef}>
        <img src={isMobile ? headerMb : headerPc} alt="header-bg" />
      </div>
      {(listLoading || historyLoading || loadingCreate) && (
        <div className="center-absolute">
          <Loading.CircleDashed width={24} />
        </div>
      )}
      <div className="p-home_container">
        <Container>
          <div
            className="p-home_infoWrapper"
            ref={infoRef}
          >
            {profileData ? (
              <div className="p-home-info">
                <Typography.Text
                  extendClasses="fs-18x22 color-white"
                  fontweight="700"
                >
                  Khách hàng:
                  {' '}
                  {profileData?.name}
                </Typography.Text>
                <Typography.Text
                  extendClasses="fs-18x22 color-white"
                  fontweight="700"
                >
                  Số điện thoại:
                  {' '}
                  {profileData?.phone}
                </Typography.Text>
                <Typography.Text
                  extendClasses="fs-18x22 color-white"
                  fontweight="700"
                >
                  Điểm hiện có:
                  {' '}
                  {profileData?.point}
                </Typography.Text>
                <Typography.Text
                  extendClasses="fs-18x22 color-white"
                  fontweight="700"
                >
                  Số quà đã đổi:
                  {' '}
                  {profileData?.exchangedAmount || 0}
                </Typography.Text>
              </div>
            ) : (
              <div className="p-home-info center">
                <span
                  onClick={() => navigator('/login')}
                  className="fs-16x20 color-white p-home-info p-home-info-link"
                >
                  Đăng nhập
                </span>
              </div>
            )}
            <Tabs value={tabValue} vertical extendClasses="p-home_tabs">
              {TabList.map((tab) => (
                <Tabs.Item
                  key={tab.value}
                  extendClasses="p-home_tabs-item"
                  value={tab.value}
                  handleChangeTab={() => setTabValue(tab.value)}
                  activeClass="p-home_tabs-active"
                >
                  <div className="p-home_tabs-text">
                    {tab.label}
                  </div>
                </Tabs.Item>
              ))}
            </Tabs>
            <div className="center w-100 u-mb-16">
              <Typography.Text
                extendClasses="fs-14x18 color-white"
                fontweight="500"
                textStyle="italic"
              >
                Vui lòng đem mã đổi thưởng đến
                quán có diễn ra chương trình để đổi thưởng
              </Typography.Text>
            </div>
          </div>
          {tabValue === 'giftsList' ? (
            <div
              className="p-home_giftsList"
              style={{
                maxHeight: `${window.innerHeight - height - 32}px`,
              }}
            >
              {dataGifts && dataGifts.length > 0
                ? dataGifts.map((item) => (
                  <div
                    key={item.id}
                    className="p-home_giftWrapper"
                  >
                    <div className={mapModifiers('p-home_giftWrapper-item', checkDisabled(item.code) && 'disabled')}>
                      <div className="p-home_giftWrapper-item-img">
                        <Image extendClasses="bg-contain" ratio={1} imgSrc={item.image} alt={String(item.id)} />
                      </div>
                      <div className="p-home_giftWrapper-item-contentWrapper">
                        <div className="p-home_giftWrapper-item-content">
                          <Typography.Text
                            extendClasses="fs-18x22 color-white"
                            fontweight="700"
                          >
                            {item.name}
                          </Typography.Text>
                          <Typography.Text
                            extendClasses="fs-16x20 color-white"
                            fontweight="600"
                          >
                            {item.point}
                            {' '}
                            điểm
                          </Typography.Text>
                        </div>
                        <div className="p-home_giftWrapper-item-btn">
                          <ButtonCustom
                            type="button"
                            disabled={checkDisabled(item.code)}
                            onClick={() => {
                              if (
                                profileData?.birthdayGiftReceived
                                && profileData?.birthdayGiftReceived.some(
                                  (el) => el.giftCode === item.code
                                )) return;
                              if (profileData) {
                                if (profileData?.point < item.point) {
                                  setModal({
                                    type: 'info',
                                    params: item.code,
                                    isOpen: true,
                                    message: 'Bạn không đủ điểm đổi quà này. Xin vui lòng chọn quà khác!',
                                    giftType: item.type
                                  });
                                } else {
                                  setModal({
                                    type: 'create',
                                    params: item.code,
                                    isOpen: true,
                                    message: 'Bạn có muốn đổi quà này ?',
                                    giftType: item.type
                                  });
                                  setExchangeItem(undefined);
                                }
                              } else {
                                setModalLogin(true);
                              }
                            }}
                          >
                            {checkDisabled(item.code) ? 'Đã đổi quà' : 'Đổi quà'}
                          </ButtonCustom>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                : (
                  <Typography.Text>Không có quà</Typography.Text>
                )}
              <div className="giftRef" ref={(suggest) => setNode(suggest)} />
            </div>
          ) : (
            <div
              className="p-home_historyList"
              style={{
                maxHeight: `${window.innerHeight - height - 32}px`,
              }}
            >
              {historyData && historyData.length > 0
                ? historyData.map((item) => (
                  <div
                    key={item.id}
                    className="p-home_historyWrapper"
                  >
                    <div className="p-home_historyWrapper-item">
                      <div className="p-home_historyWrapper-item-img">
                        <Image extendClasses="bg-contain" ratio={1} imgSrc={item.gifts ? item.gifts[0].image : ''} alt={String(item.id)} />
                      </div>
                      <div className="p-home_historyWrapper-item-contentWrapper">
                        <div className="p-home_historyWrapper-item-content">
                          <Typography.Text
                            extendClasses="fs-18x22 color-white"
                            fontweight="700"
                          >
                            {item.gifts.map((el) => el.name).join(', ')}
                          </Typography.Text>
                          <Typography.Text
                            extendClasses={`fs-16x20 color-${item.status === 'completed' ? 'khaki' : 'kellyGreen'}`}
                            fontweight="600"
                          >
                            Trạng thái:
                            {' '}
                            {item.status === 'completed' ? 'Mã đã sử dụng' : 'Mã chưa được sử dụng'}
                          </Typography.Text>
                          {item.completedAt && (
                            <Typography.Text
                              extendClasses="fs-16x20 color-khaki"
                              fontweight="600"
                            >
                              Thời gian đổi quà:
                              {' '}
                              {dayjs(item.completedAt).format('DD/MM/YY HH:mm')}
                            </Typography.Text>
                          )}
                        </div>
                        <div className="p-home_historyWrapper-item-btn">
                          <ButtonCustom
                            type="button"
                            onClick={() => {
                              setModal({
                                type: 'info',
                                params: '',
                                isOpen: true,
                                message: 'Thông tin mã đổi'
                              });
                              setExchangeItem(item);
                            }}
                          >
                            Mã đổi quà
                          </ButtonCustom>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                : (
                  <Typography.Text
                    textStyle="center"
                    fontweight="600"
                    extendClasses="fs-16x24 color-white w-100"
                  >
                    {profileData ? 'Không có dữ liệu' : 'Vui lòng đăng nhập để xem lịch sử đổi quà'}
                  </Typography.Text>
                )}
              <div className="historyRef" ref={(suggest) => setNode(suggest)} />
            </div>
          )}
          <CustomModal
            isOpen={modal.isOpen}
            handleClose={() => setModal({ ...modal, isOpen: false })}
            isSmall
            variant={exchangeItem && 'default'}
            noButton={modal.type !== 'info'}
          >
            <Typography.Text
              textStyle="center"
              fontweight="600"
              extendClasses={`fs-16x24 ${exchangeItem ? 'color-white' : 'color-cadmiumRed'}`}
            >
              {modal.message}
            </Typography.Text>
            {exchangeItem && (
              <div className="u-mt-16">
                <div className="p-home_historyList-content">
                  <div className="p-home_historyWrapper-item-img u-mb-16">
                    <Image extendClasses="bg-contain" ratio={1} imgSrc={exchangeItem.gifts ? exchangeItem.gifts[0].image : ''} alt={String(exchangeItem.id)} />
                  </div>
                  <div className="p-home_historyList-name">
                    <div className="u-mb-4">
                      <Typography.Text
                        extendClasses="fs-18x22 color-white"
                        fontweight="700"
                      >
                        {exchangeItem.gifts.map((el) => el.name).join(', ')}
                      </Typography.Text>
                    </div>
                    <Typography.Text
                      extendClasses="fs-16x20 color-white"
                      fontweight="600"
                    >
                      Thời gian đổi điểm:
                      {' '}
                      {dayjs(exchangeItem.createdAt).format('DD/MM/YY HH:mm')}
                    </Typography.Text>
                    <Typography.Text
                      extendClasses={`fs-16x20 color-${exchangeItem.status === 'completed' ? 'khaki' : 'kellyGreen'}`}
                      fontweight="600"
                    >
                      Trạng thái:
                      {' '}
                      {exchangeItem.status === 'completed' ? 'Mã đã sử dụng' : 'Mã chưa được sử dụng'}
                    </Typography.Text>
                    {exchangeItem.completedAt && (
                      <Typography.Text
                        extendClasses="fs-16x20 color-khaki"
                        fontweight="600"
                      >
                        Thời gian đổi quà:
                        {' '}
                        {dayjs(exchangeItem.completedAt).format('DD/MM/YY HH:mm')}
                      </Typography.Text>
                    )}
                  </div>
                </div>
                <div className="p-home_historyList-qr">
                  <QRCode
                    size={256}
                    style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                    value={exchangeItem.qrUuid}
                    viewBox="0 0 256 256"
                  />
                </div>
              </div>
            )}
            {modal.type !== 'info' && (
              <div className="p-home_btnGroup">
                <div className="p-home_btnGroup-item">
                  <ButtonCustom
                    type="button"
                    variant="outline"
                    onClick={() => {
                      setModal({ ...modal, isOpen: false });
                      setExchangeItem(undefined);
                    }}
                  >
                    Đóng
                  </ButtonCustom>
                </div>
                <div className="p-home_btnGroup-item">
                  <ButtonCustom
                    type="button"
                    onClick={() => handleClickModal(modal.params, modal.type, modal.giftType || 'normal')}
                    variant="red"
                  >
                    Xác nhận
                  </ButtonCustom>
                </div>
              </div>
            )}
          </CustomModal>
          <CustomModal
            isOpen={modalSuccess.isOpen}
            handleClose={() => setModalSuccess({ ...modalSuccess, isOpen: false })}
            isSmall
            noButton
            variant="success"
          >
            <Typography.Text
              textStyle="uppercase"
              fontweight="700"
              extendClasses="fs-18x22 color-white"
            >
              Bạn đã đổi quà thành công!
            </Typography.Text>
            <Typography.Text
              textStyle="uppercase"
              fontweight="700"
              extendClasses="fs-18x22 color-white"
            >
              {modalSuccess.message}
            </Typography.Text>
            <div className="o-modal-success_img u-mt-16 center">
              <Image extendClasses="bg-contain" ratio={1} imgSrc={modalSuccess.image} alt="modal-image" />
            </div>
          </CustomModal>
          <CustomModal
            isOpen={modalLogin}
            handleClose={() => setModalLogin(false)}
            isSmall
            noButton
          >
            <div className="center w-100">
              <Typography.Text
                textStyle="uppercase"
                fontweight="700"
                extendClasses="fs-18x22"
              >
                Quý khách cần đăng nhập để tiến hành đổi quà
              </Typography.Text>
            </div>
            <div className="p-home_btnGroup">
              <div className="p-home_btnGroup-item">
                <ButtonCustom
                  type="button"
                  variant="outline"
                  onClick={() => {
                    setModalLogin(false);
                  }}
                >
                  Đóng
                </ButtonCustom>
              </div>
              <div className="p-home_btnGroup-item">
                <ButtonCustom
                  type="button"
                  onClick={() => {
                    setModalLogin(false);
                    navigator('/login');
                  }}
                  variant="red"
                >
                  Xác nhận
                </ButtonCustom>
              </div>
            </div>
          </CustomModal>
          {/* update form */}
          <CustomModal
            isOpen={modalUpdate}
            handleClose={() => setModalUpdate(false)}
            isSmall
            noButton
            variant="red"
          >
            <div className="center w-100">
              <Typography.Text
                textStyle="uppercase"
                fontweight="700"
                extendClasses="fs-18x22 color-white"
              >
                Quý khách vui lòng nhập ngày, tháng, năm sinh!
              </Typography.Text>
            </div>
            <div className="u-mt-16">
              <FormProvider {...method}>
                <Controller
                  name="birthday"
                  render={({ field: { onChange } }) => (
                    <DatePickerCustom
                      placeholder="Nhập ngày tháng năm"
                      handleSelectDate={onChange}
                    />
                  )}
                />
              </FormProvider>
            </div>

            <div className="p-home_btnGroup">
              <div className="p-home_btnGroup-item">
                <ButtonCustom
                  type="submit"
                  onClick={method.handleSubmit(handleUpdate)}
                  variant="outline"
                >
                  Xác nhận
                </ButtonCustom>
              </div>
            </div>
          </CustomModal>
        </Container>
      </div>
      <ModalPolicy
        isOpen={modalPolicy}
        handleClose={() => setModalPolicy(false)}
      />
    </div>
  );
};
export default Home;
