import {
  ParamsType, GiftData, PointExchangeDataList, CreatePointExchangeParams
} from './types';

import axiosInstance from 'services/common/instance';

export const getProjectGiftsService = async (
  params: ParamsType
): Promise<APIPaginationResponse<GiftData[]>> => {
  const { projectCode, ...rest } = params;
  const res = await axiosInstance.get(`rewards/projects/${projectCode}/gifts`, {
    params: rest,
  });
  return res.data;
};

export const getRewardHistoryService = async (
  params?: ParamsType
): Promise<APIPaginationResponse<PointExchangeDataList[]>> => {
  const res = await axiosInstance.get('rewards/point-exchanges', { params });
  return res.data;
};

export const createPointExchangeService = async (
  params: CreatePointExchangeParams
): Promise<void> => {
  const res = await axiosInstance.post('rewards/point-exchanges', { ...params });
  return res.data;
};
