import React from 'react';
import { Typography } from 'tfc-components';

import ButtonCustom from 'components/atoms/ButtonCustom';

interface Step2Props {
  handleStep: React.Dispatch<React.SetStateAction<number>>;
  phone: string
}

const Step2: React.FC<Step2Props> = ({
  handleStep,
  phone
}) => (
  <div className="p-login_step2">
    <Typography.Heading
      extendClasses="p-login_heading fs-24x30 color-white"
      fontweight="600"
      textStyle="center"
    >
      GỬI MÃ XÁC THỰC
    </Typography.Heading>
    <Typography.Text
      extendClasses="p-login_subHeading fs-16x20 color-white"
      textStyle="center"
    >
      Mã xác thực sẽ được gửi đến số điện thoại
      <br />
      {phone || ''}
    </Typography.Text>
    <Typography.Text
      extendClasses="p-login_subHeading fs-16x20 color-white"
      textStyle="center"
    >
      Vui lòng chọn hình thức nhận mã
    </Typography.Text>
    <ButtonCustom
      onClick={() => handleStep(3)}
    >
      NHẬN QUA ZALO
    </ButtonCustom>
  </div>
);

export default React.memo(Step2);
