import React from 'react';
import {
  Typography
} from 'tfc-components';

import CustomModal from 'components/organisms/Modal';
import { policy } from 'utils/constant';

type ModalPolicyProps = {
  isOpen: boolean;
  handleClose: () => void;
};

const ModalPolicy: React.FC<ModalPolicyProps> = ({
  isOpen,
  handleClose,
}) => (
  <CustomModal
    isOpen={isOpen}
    handleClose={handleClose}
    noButton
    variant="default"
  >
    <Typography.Heading
      textStyle="center"
      fontweight="600"
      extendClasses="fs-18x22 color-white"
    >
      THỂ LỆ CHƯƠNG TRÌNH KHUYẾN MẠI
    </Typography.Heading>
    <div className="u-mt-4">
      <Typography.Heading
        textStyle="center"
        fontweight="600"
        extendClasses="fs-18x22 color-white"
      >
        “TÍCH ĐIỂM ĐỔI QUÀ BIA VIỆT”
      </Typography.Heading>
    </div>
    <div
      className="p-home_modalContent u-mt-16"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: policy }}
    />
  </CustomModal>
);

export default ModalPolicy;
