/* eslint-disable import/prefer-default-export */
import {
  vi, ja, ru, enAU, ko,
} from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';

// https://date-fns.org/v2.0.0-alpha.18/docs/I18n
registerLocale('vi', vi); // Vietnamese
registerLocale('ja', ja); // Japanese
registerLocale('ru', ru); // Russian
registerLocale('enAU', enAU); // English (USA)
registerLocale('ko', ko); // Korean

export const datePickerLocalized = (locale?: string) => {
  switch (locale) {
    case 'vi':
      return vi;
    case 'ja':
      return ja;
    case 'ru':
      return ru;
    case 'ko':
      return ko;
    default:
      return enAU;
  }
};
