import React, { useEffect, useState } from 'react';
import { Typography } from 'tfc-components';

interface CountdownProps {
  totalSeconds: number;
  isReset?: boolean;
  setIsReset?: (value: boolean) => void;
}

const Countdown: React.FC<CountdownProps> = ({
  isReset,
  setIsReset,
  totalSeconds,
}) => {
  const [timeLeft, setTimeLeft] = useState<number>(totalSeconds);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft && prevTimeLeft <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTimeLeft && prevTimeLeft - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [totalSeconds]);

  useEffect(() => {
    if (isReset && setIsReset) {
      setTimeLeft(totalSeconds);
      setIsReset(false);
    }
  }, [totalSeconds, setIsReset, isReset]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <span className="m-countdown">
      <Typography.Text
        type="span"
        extendClasses="p-login_subHeading fs-16x20 color-white"
        textStyle="center"
      >
        {formatTime(timeLeft)}
      </Typography.Text>
    </span>
  );
};

export default Countdown;
