/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Typography, Image } from 'tfc-components';

import hvnImage from 'assets/images/hvnImage.png';
import Container from 'components/organisms/Container';

const Policy: React.FC = () => (
  <div className="p-policy">
    <Container>
      <div className="p-policy_logo">
        <Image
          extendClasses="bg-contain"
          ratio={512 / 213}
          imgSrc={hvnImage}
          alt="hvnLogo"
        />
      </div>
      <h3 className="p-policy_title">
        THÔNG BÁO ĐIỀU KHOẢN BẢO VỆ DỮ LIỆU CÁ NHÂN CỦA KHÁCH HÀNG
        <br />
        LIÊN QUAN ĐẾN ỨNG DỤNG CRM – ZALO OA BIA VIỆT
        <br />
        (SAU ĐÂY VIẾT TẮT LÀ “THÔNG BÁO”)
      </h3>
      <div className="p-policy_title-divider" />
      <div className="p-policy_content">
        <p>
          Công ty TNHH Nhà máy Bia HEINEKEN Việt Nam (Sau đây viết tắt là "HVN"
          hoặc "Chúng tôi" hoặc “Công ty”) và các công ty liên kết, các chi
          nhánh và văn phòng đại diện của HVN cam kết bảo vệ quyền riêng tư của
          bạn.
          <br />
          <br />
          Chúng tôi tôn trọng quyền riêng tư của bạn và cam kết bảo mật Dữ liệu
          Cá nhân của bạn và quản lý dữ liệu bằng trách nhiệm pháp lý của Chúng
          tôi theo quy định hiện hành về bảo vệ dữ liệu cá nhân.
          <br />
          <br />
          Trong Thông báo này, Chúng tôi mô tả cách Chúng tôi xử lý và bảo vệ dữ
          liệu cá nhân của bạn liên quan đến ứng dụng CRM – ZALO OA BIA VIỆT,
          Chúng tôi là Người kiểm soát dữ liệu cá nhân của bạn.
        </p>
        <br />
        <b>1. Chúng tôi sử dụng dữ liệu cá nhân của bạn cho mục đích gì</b>
        <br />
        <br />
        Chúng tôi sử dụng dữ liệu cá nhân của bạn nhằm các mục đích sau đây:
        <br />
        <br />
        <ul>
          <li>Đăng ký và tạo tài khoản của bạn trên Trang quản trị.</li>
          <li>
            Xử lý thông tin và hình ảnh của bạn như tên, ngày sinh, tỉnh thành
            sinh sống, số điện thoại, hình ảnh đổi quà trên hệ thống, thời gian
            đổi quà, phần quà được đổi, số lượng sản phẩm sử dụng,...
          </li>
          <li>
            Chúng tôi cần những thông tin trên để đảm bảo tính chính xác về quà
            tặng được gửi đến người tiêu dùng và quản lý hiệu suất của toàn bộ
            chương trình.
          </li>
          <li>
            Cá nhân hóa thông điệp quảng cáo trên các kênh sở hữu và trả phí
          </li>
          <li>Phân tích nội bộ, phân khúc, thấu hiểu khách hàng</li>
          <li>
            Tham gia sự kiện, bốc thăm trúng thưởng, phát quà, nhà nước kiểm
            toán
          </li>
          <li>
            Cung cấp hỗ trợ bán hàng và dịch vụ sau bán hàng, đường dây nóng
          </li>
        </ul>
        <br />
        <b>2. Chúng tôi sử dụng loại dữ liệu cá nhân nào</b>
        <br />
        <br />
        Để đăng ký sử dụng và trải nghiệm ứng dụng, chúng tôi cần bạn cung cấp
        những thông tin sau: họ và tên, ngày sinh, số điện thoại, tỉnh thành
        sinh sống.
        <br />
        <br />
        Trong quá trình sử dụng Ứng dụng, chúng tôi sẽ thu thập dữ liệu về địa
        điểm của bạn trong quá trình bạn sử dụng sản phẩm của chúng tôi ở thị
        trường.
        <br />
        <br />
        Khi bạn đổi quà, chúng tôi thu thập quà tặng mà bạn đổi để xác định
        thông tin chính xác về thị trường và không thể sử dụng cho bất kỳ mục
        đích nào khác.
        <br />
        <br />
        Thông tin của bạn mà chúng tôi thu thập và xử lý sẽ đầy đủ, có liên quan
        và chỉ phục vụ cho những mục đích cụ thể được nêu ở mục 1 của Thông báo
        này. Những thông tin của bạn cần chính xác nhất có thể và cần tuân thủ
        theo quy định hiện hành về bảo vệ dữ liệu cá nhân.
        <br />
        <br />
        <b>3. Phương thức xử lý dữ liệu cá nhân của bạn</b>
        <br />
        <br />
        Bằng cách đọc và cung cấp sự đồng ý của bạn đối với Thông báo này, bạn
        đồng ý rằng thông tin của mình sẽ được thu thập, lưu trữ, và sử dụng
        bằng bất kỳ phương thức nào theo các chính sách và/hoặc tình hình hoạt
        động của Công ty được điều chỉnh theo thời gian, bao gồm nhưng không
        giới hạn, thu thập, ghi, xác nhận, lưu trữ, chỉnh sửa, kết hợp, truy
        cập, truy xuất, thu hồi, mã hóa, giải mã, sao chép, chuyển giao, xóa,
        hủy và các hành động khác có liên quan. Tất cả phương thức này phải được
        phù hợp với mục đích đề ra tại mục 1 của Thông báo này.
        <br />
        <br />
        <b>4. Ai có quyền truy cập dữ liệu cá nhân của bạn</b>
        <br />
        <br />
        Chỉ có những cá nhân liên quan đến dự án như Công ty, công ty quảng cáo
        đang thực hiện chương trình và nhóm dự án của công ty mới có quyền truy
        cập vào dữ liệu cá nhân của bạn để thực hiện công việc thuộc trách nhiệm
        của mình và thực hiện bất kỳ mục đích nào được mô tả trong Thông báo
        này.
        <br />
        <br />
        <b>5. Hậu quả, thiệt hại không mong muốn có khả năng xảy ra</b>
        <br />
        <br />
        Chúng tôi xem các dữ liệu cá nhân của các bạn như là tài sản quan trọng
        của Công ty và Chúng tôi sẽ đảm bảo tính bảo mật, an toàn, tuân thủ pháp
        luật, và hạn chế các hậu quả, thiệt hại không mong muốn có khả năng xảy
        ra (bao gồm nhưng không giới hạn: rò rỉ dữ liệu hoặc xử lý dữ liệu không
        phù hợp gây tổn hại đến quyền và lợi ích hợp pháp của bạn).
        <br />
        <br />
        <b>6. Bảo mật</b>
        <br />
        <br />
        Chúng tôi sẽ thực hiện các biện pháp kỹ thuật, vật lý và tổ chức phù hợp để bảo vệ dữ liệu cá nhân khỏi bị lạm dụng hoặc phá hủy, mất mát, thay đổi, tiết lộ, thu thập hoặc truy cập vô tình, trái pháp luật hoặc trái phép.
        <br />
        <br />
        Chúng tôi không chia sẻ dữ liệu cá nhân của bạn được thu thập cho thiết bị này cho bất kỳ bên thứ ba nào.
        <br />
        <br />
        Chúng tôi sẽ thông báo cho bạn về Vi phạm Bảo mật Dữ liệu trong một khoảng thời gian hợp lý sau khi phát hiện ra vi phạm đó, trừ khi một quan chức thực thi pháp luật hoặc cơ quan giám sát xác định rằng thông báo đó sẽ cản trở một cuộc điều tra (hình sự) hoặc gây thiệt hại cho an ninh quốc gia. Trong trường hợp này, việc thông báo sẽ bị trì hoãn theo hướng dẫn của cơ quan đó. Chúng tôi sẽ tiến hành điều tra nhanh chóng về Nhân viên liên quan đến Vi phạm Bảo mật Dữ liệu đó.
        <br />
        <br />
        <b>7. Chúng tôi lưu trữ dữ liệu cá nhân của bạn trong bao lâu</b>
        <br />
        <br />
        Chúng tôi sẽ lưu trữ dữ liệu cá nhân của bạn trong khoảng thời gian pháp luật yêu cầu hoặc khoảng thời gian cần thiết cho bất kỳ mục đích nào được liệt kê trong Thông báo này, hoặc để tuân thủ các yêu cầu pháp lý mà Chúng tôi phải tuân theo, miễn là cần thiết một cách hợp lý cho mục đích lưu trữ hoặc miễn là phù hợp với thời hiệu áp dụng. Chúng tôi sẽ thực hiện các bước hợp lý để hủy hoặc hủy nhận dạng dữ liệu cá nhân mà Chúng tôi lưu trữ nếu dữ liệu đó không còn cần thiết cho các mục đích nêu trên hoặc sau khi hết thời hạn lưu trữ đã xác định.
        <br />
        <br />
        Thời gian chúng tôi bắt đầu xử lý những dữ liệu cá nhân: sau khi chủ thể dữ liệu đồng ý với Thông báo này.
        <br />
        <br />
        Thời gian kết thúc việc xử lý những dữ liệu cá nhân: 05 năm kể từ thời điểm bắt đầu xử lý dữ liệu cá nhân.
        <br />
        <br />
        <b>8. Quyền và nghĩa vụ của bạn</b>
        <br />
        <br />
        Bạn có quyền đối với dữ liệu cá nhân của mình, trong đó bao gồm: quyền đồng ý, quyền rút lại sự đồng ý, quyền xóa dữ liệu, quyền hạn chế xử lý dữ liệu và các quyền khác theo quy định của pháp luật hiện hành về bảo vệ dữ liệu cá nhân.
        <br />
        <br />
        Bạn có nghĩa vụ: tự bảo vệ dữ liệu cá nhân của mình; tôn trọng, bảo vệ dữ liệu cá nhân của người khác; cung cấp đầy đủ, chính xác dữ liệu cá nhân khi đồng ý cho phép xử lý dữ liệu cá nhân; và các nghĩa vụ khác theo quy định của pháp luật hiện hành về bảo vệ dữ liệu cá nhân.
        <br />
        <br />
        <b>9. Truy cập và yêu cầu chỉnh sửa, thắc mắc và khiếu nại</b>
        <br />
        <br />
        Vui lòng cập nhật dữ liệu cá nhân của bạn và thông báo cho Chúng tôi về bất kỳ thay đổi quan trọng nào.
        <br />
        <br />
        Bạn có quyền yêu cầu cung cấp dữ liệu tổng hợp về dữ liệu cá nhân của bạn đã được Chúng tôi xử lý. Bạn có quyền yêu cầu chỉnh sửa, xóa hoặc hạn chế dữ liệu của mình (nếu cần thiết) theo qui định hiện hành về bảo vệ dữ liệu cá nhân cũng như các qui định và hướng dẫn liên quan khác của Công ty.
        <br />
        <br />
        Trong một số trường hợp nhất định, bạn cũng có quyền yêu cầu Chúng tôi ngừng xử lý dữ liệu cá nhân của bạn. Tuy nhiên, Chúng tôi sẽ không thể giải quyết yêu cầu này nếu Chúng tôi có cơ sở hợp pháp để tiếp tục xử lý dữ liệu cá nhân của bạn. Khi bạn đã đồng ý cho Chúng tôi sử dụng dữ liệu cá nhân của bạn, bạn có quyền rút lại sự đồng ý của mình nếu không ảnh hưởng đến tính hợp pháp của việc Chúng tôi sử dụng dữ liệu này trước khi bạn rút lại.
        <br />
        <br />
        Trong trường hợp bạn muốn thực hiện các yêu cầu trên hoặc báo cáo vi phạm dữ liệu, vui lòng liên hệ với Chúng tôi qua địa chỉ
        {' '}
        <a href="mailto:privacyvn@heineken.com">privacyvn@heineken.com</a>
        {' '}
        hoặc Hotline
        {' '}
        <a href="tel:19001845">19001845</a>
        {' '}
        để được hỗ trợ.
        <br />
        <br />
        <b>10. The Notice Validity</b>
        <br />
        <br />
        Thông báo này sẽ có hiệu lực từ ngày 01 tháng 05 năm 2024. Thông báo có thể được điều chỉnh tùy theo tình hình hoạt động và việc cập nhật Thông báo sẽ được gửi cho bạn đọc và xác nhận đồng ý.
      </div>
    </Container>
  </div>
);

export default Policy;
