import React from 'react';
import { Button, Loading, Typography } from 'tfc-components';

import mapModifiers from 'utils/functions';

interface ButtonCustomProps {
  children?: React.ReactNode;
  onClick?: () => void;
  type?: 'submit' | 'button';
  loading?: boolean;
  disabled?: boolean;
  variant?: 'm' | 'l' | 'red' | 'outline';
  className?: string;
  icon?: React.ReactNode,
  isNormal?: boolean
}

const ButtonCustom: React.FC<ButtonCustomProps> = ({
  children,
  onClick,
  type,
  disabled,
  loading,
  variant,
  className,
  icon,
  isNormal
}) => (
  <Button
    extendClasses={`${mapModifiers('a-button', variant)} ${className}`}
    type={type}
    onClick={onClick}
    disabled={disabled}
    loading={loading}
    icon={icon}
    loadingIndicator={loading && (
      <div className="a-button_loadingIndicator">
        <Loading.CircleDashed width={24} />
      </div>
    )}
  >
    <Typography.Text
      extendClasses={`fs-16x20 ${variant === 'red' ? 'color-white' : 'color-venetianRed'}`}
      textStyle={isNormal ? 'normal' : 'uppercase'}
      fontweight="600"
    >
      {children}
    </Typography.Text>
  </Button>
);

export default ButtonCustom;
