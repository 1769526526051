import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  Typography
} from 'tfc-components';

import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

import MainLayout from 'components/organisms/MainLayout';
import CustomModal from 'components/organisms/Modal';
import { getAccessToken, setAccessToken } from 'services/common/storage';
import { loginCustomerService } from 'services/customer';
import { LoginParams } from 'services/customer/types';
import { setUserProfile } from 'store/auth';
import { useAppDispatch } from 'store/hooks';
import { PROJECT_CODE } from 'utils/constant';
import useSchemas from 'utils/shema';

const Login: React.FC = () => {
  const token = getAccessToken();
  const dispatch = useAppDispatch();
  const navigator = useNavigate();
  const [step, setStep] = useState(1);
  const [modalLogin, setModalLogin] = useState(false);

  const { loginSchema } = useSchemas();
  const method = useForm<LoginParams>({
    mode: 'onSubmit',
    resolver: yupResolver(loginSchema),
    defaultValues: {
      phone: '',
      project_code: '',
      password: ''
    },
  });

  const phoneNumber = method.getValues('phone');

  const { mutate: loginMutate } = useMutation(
    ['loginCustomerService', phoneNumber],
    async (otp: string) => loginCustomerService({
      password: otp,
      project_code: PROJECT_CODE,
      phone: phoneNumber,
    }),
    {
      onSuccess: (data) => {
        setAccessToken(data.token);
        dispatch(setUserProfile(data.user));
        navigator('/');
      },
      onError: () => {
        setModalLogin(true);
      }
    }
  );

  useEffect(() => {
    if (token) {
      navigator('/');
    }
  }, [token, navigator]);

  return (
    <div className="p-login mainBackground-bottom">
      <MainLayout>
        <FormProvider {...method}>
          {(() => {
            switch (step) {
              case 1: return (
                <Step1
                  handleStep={setStep}
                />
              );
              case 2: return (
                <Step2
                  handleStep={setStep}
                  phone={phoneNumber}
                />
              );
              case 3: return (
                <Step3
                  phone={phoneNumber}
                  handleVerify={(data) => loginMutate(data)}
                  handleResetStep={setStep}
                />
              );
              default: return null;
            }
          })()}
        </FormProvider>
        <CustomModal
          isOpen={modalLogin}
          handleClose={() => setModalLogin(false)}
          isSmall
          noButton
        >
          <div className="center w-100">
            <Typography.Text
              textStyle="uppercase"
              fontweight="700"
              extendClasses="fs-18x22"
            >
              Mã OTP chưa đúng! Xin vui lòng thử lại
            </Typography.Text>
          </div>
        </CustomModal>
      </MainLayout>
    </div>
  );
};
export default Login;
