/* eslint-disable prefer-regex-literals */
import * as yup from 'yup';

import { LoginParams } from 'services/customer/types';

export const phoneRegExp = /^0[1-9]\d{8}$/;

const useSchemas = () => {
  const loginSchema: yup.ObjectSchema<LoginParams> = yup.object().shape({
    phone: yup.string().required('Vui lòng cung cấp thông tin').matches(phoneRegExp, 'Số điện thoại chưa hợp lệ'),
    project_code: yup.string().required('Vui lòng cung cấp thông tin'),
    password: yup.string().required('Vui lòng cung cấp thông tin'),
  });

  return {
    loginSchema,
  };
};

export default useSchemas;
