import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Typography } from 'tfc-components';

import ButtonCustom from 'components/atoms/ButtonCustom';
import Countdown from 'components/molecules/Countdown';
import CustomModal from 'components/organisms/Modal';
import OTP from 'components/organisms/Otp';
import { requestOTPService } from 'services/customer';
import { PROJECT_CODE } from 'utils/constant';

const TIME = 120;

interface Step3Props {
  phone: string
  handleVerify?: (data: string) => void;
  handleResetStep?: (step: number) => void;
}

const Step3: React.FC<Step3Props> = ({ phone, handleVerify, handleResetStep }) => {
  const [isReset, setIsReset] = useState<boolean>(false);
  const [modal, setModal] = useState({
    isOpen: false,
    message: ''
  });
  const { mutate: requestOTPMutate } = useMutation(
    ['requestOTPMutate'],
    async () => requestOTPService({
      project_code: PROJECT_CODE,
      phone,
    }),
    {
      onSuccess: () => {
        setModal({
          isOpen: true,
          message: 'Otp đã được gửi! Vui lòng kiểm tra lại'
        });
      },
      onError: () => {
        setModal({
          isOpen: true,
          message: 'Gửi Otp thất bại! Vui lòng kiểm tra lại'
        });
      }
    }
  );

  useEffect(() => {
    requestOTPMutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-login_step3">
      <Typography.Heading
        extendClasses="p-login_heading fs-24x30 color-white"
        fontweight="600"
        textStyle="center"
      >
        NHẬP MÃ XÁC THỰC
      </Typography.Heading>
      <Typography.Text
        extendClasses="p-login_subHeading fs-16x20 color-white"
        textStyle="center"
      >
        Mã xác thực gửi đến số điện thoại
        <br />
        {phone || ''}
      </Typography.Text>
      <div className="center u-mt-16">
        <Typography.Text
          type="span"
          extendClasses="p-login_subHeading fs-16x20 color-white"
          textStyle="center"
        >
          Có hiệu lực trong:
          {' '}
        </Typography.Text>
        <Countdown totalSeconds={TIME} isReset={isReset} setIsReset={setIsReset} />
      </div>
      <div className="u-mt-16">
        <ButtonCustom
          type="button"
          onClick={() => handleResetStep && handleResetStep(1)}
        >
          Đổi số điện thoại
        </ButtonCustom>
      </div>
      <OTP
        handleVerify={handleVerify}
        handleResendOTP={() => {
          requestOTPMutate();
          setIsReset(true);
        }}
      />
      <CustomModal
        isOpen={modal.isOpen}
        handleClose={() => setModal({ ...modal, isOpen: false })}
        isSmall
        noButton
      >
        <Typography.Text
          textStyle="center"
          fontweight="600"
          extendClasses="fs-16x24 color-cadmiumRed"
        >
          {modal.message}
        </Typography.Text>
      </CustomModal>
    </div>
  );
};

export default React.memo(Step3);
