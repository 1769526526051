import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { Icon } from 'tfc-components';

import ButtonCustom from 'components/atoms/ButtonCustom';
import mapModifiers from 'utils/functions';

export type VariantModal = 'default' | 'success' | 'red';

interface ModalProps {
  isOpen: boolean;
  handleClose?: () => void;
  variant?: VariantModal;
  children?: React.ReactNode;
  modifiers?: string[];
  noButton?: boolean;
  isSmall?: boolean
}

const CustomModal: React.FC<ModalProps> = ({
  isOpen,
  handleClose,
  variant,
  children,
  modifiers,
  noButton,
  isSmall
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100vh';
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.height = 'auto';
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className={mapModifiers('o-modal', variant)}
      ariaHideApp={false}
      portalClassName={mapModifiers('o-modal_portal', isOpen && 'open')}
      overlayClassName={mapModifiers('o-modal_overlay', variant)}
    >
      <div className={mapModifiers('o-modal_main', isSmall && 'small')}>
        <div className={mapModifiers('o-modal_wrapper', modifiers)}>
          <button type="button" className="o-modal_close" onClick={handleClose}>
            <Icon icon="close" />
          </button>
          <div className="o-modal_body">
            {children}
          </div>
          {
            !noButton && (
              <div className="o-modal_button">
                <ButtonCustom type="button" onClick={handleClose}>
                  Đóng
                </ButtonCustom>
              </div>
            )
          }
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
